<template>
  <Container center>
    <section class="col-10 col-sm-12 col-lg-6 text-center my-5">
      <h1><b>Ooops...</b></h1>
      <img src="https://vvs.autosyncstudio.com/static/icons/car-damaged.png" alt="" class="w-100 my-5" style="max-width: 200px">
      <h6 class="text-muted my-2">Something happened.<br>Please go back and try something diferent.</h6>
    </section>
  </Container>
</template>

<script>
import Container from "@/components/Container.vue";

export default {
  name: 'ServerError',
  components: {
    Container
  }
}
</script>
